import React, { Component } from "react";
import code from "../assets/code.jpg";

const Joke = ({ joke: { setup, punchline } }) => (
  <p style={{ margin: 20 }}>
    {setup} <em>{punchline}</em>
  </p>
);

class Jokes extends Component {
  state = { joke: {}, jokes: [] };

  componentDidMount() {
    fetch("https://official-joke-api.appspot.com/random_joke")
      .then((response) => response.json())
      .then((json) => this.setState({ joke: json }))
      .catch((error) => alert(error.message));
  }

  fetchJokes = () => {
    fetch("https://official-joke-api.appspot.com/jokes/programming/ten")
      .then((response) => response.json())
      .then((json) => this.setState({ jokes: json }))
      .catch((error) => alert(error.message));
  };

  render() {
    return (
      <div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', margin:'80px 80px 0 80px ',textAlign:'start', textIndent: "50px"}}>
          <img src={code} style = {{with:'400px', height:'400px', marginRight:'50px'}}/>
         <div>
          <h1>The funny side of programmer's life</h1>
          <p>Developers life contains some funny but true situations. 
            Here are some programming puns that every coder will definitely relate to.
             Check out the <a href="https://github.com/15Dkatz/official_joke_api">Official Joke API </a> for more jokes!</p>
         
          <div class="card text-center" style={{border:'1px solid #D4D4D4', margin:'0 30px'}}>
            <div class="card-header">
            Highlighted Joke
            </div>
            <hr/>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p>{this.state.joke.setup}</p>
                <p><i>- {this.state.joke.punchline}</i></p>
              </blockquote>
            </div>
          </div>
         </div>
        </div>
        <hr />
        <h3>Want ten new jokes?</h3>
        <button className="btn btn-m" style={{backgroundColor:"#0B5345", color:"white"}} onClick={this.fetchJokes}>Click me!</button>
        {this.state.jokes.map((joke) => (
          <Joke key={joke.id} joke={joke} />
        ))}
      </div>
    );
  }
}

export default Jokes;
