import React from "react";
import { Link } from "react-router-dom";
import transformation from '../assets/transformation.svg'
import SocialProfiles from "./SocialProfiles";

const Header = ({ children }) => {
  
  return (
    <div  id="page-container">
      <section className = "nav-bar">
        <div style= {{marginLeft: "20px" , display:"flex", flexDirection:"row", alignItems:"baseline"}} > 
          <h2 > <Link to="/"> &#123; Ana_Tulea/ &#125; </Link></h2>
          <img src={transformation} alt='profile' style={{ width: 40, height: 40 }}  />
        </div>
        <div className='nav-link'>
          <h3>
            <Link to="/">Home</Link>
          </h3>
          <h3>
            <Link to="/jokes">Jokes</Link>
          </h3>
          <h3>
            <Link to="/music-master">Music Master</Link>
          </h3>
          <h3>
            <Link to="/evens-or-odds">Evens or Odds</Link>
          </h3>
          {/* <h3>
            <Link to="/reaction">Reaction</Link>
          </h3> */}
          <h3>
            <Link to="/contact">Contact</Link>
          </h3>
        </div>
      </section>
      {children}
      <SocialProfiles/>
    </div>
  );
};

export default Header;
