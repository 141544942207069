import React, { Component } from "react";
import Projects from "./Projects";
import Title from "./Title";
import AboutMe from './AboutMe';
import profile from "../assets/anatulea.jpeg";


class App extends Component {
  state = { displayBio: false };

  toggleDisplayBio = () => {
    this.setState({ displayBio: !this.state.displayBio });
  };

  render() {
    return (
      <div style={{marginTop:"10px"}} >
        <img src={profile} alt="profile" className="profile" />
        <h1>Hello World!</h1>
        <p>My name is Ana.</p>
        <Title />
        <p>
          I am a Full-Stack Web Developer.
          Passionate about design and the backstage of web applications.
        </p>
        <h4>
          JavaScript | React | Redux | Node | Python | Express | Knex | R | AWS | PostgreSQL | Flask
          | HTML5 | CSS3 | LESS/SASS | Bootstrap {" "}
        </h4>
        {this.state.displayBio ? (
           <div>
             <hr/>
             <AboutMe/>
             <button className="btn btn-m" style={{backgroundColor:"#0B5345", color:"white"}} onClick={this.toggleDisplayBio}>Show less</button>
           </div>
        ) : (
          <div>
            <button  className="btn btn-m" style={{backgroundColor:"#0B5345", color:"white"}} onClick={this.toggleDisplayBio}>Read more</button>
          </div>
        )}
        <hr />
        <Projects />
      </div>
    );
  }
}
export default App;
