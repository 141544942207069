import React, { Component } from 'react';

class Search extends Component {
  state = { artistQuery: '' };

  updateArtistQuery = event => {
    this.setState({ artistQuery: event.target.value });
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.searchArtist();
    }
  }

  searchArtist = () => {
    this.props.searchArtist(this.state.artistQuery);
  }

  render() {
    return (
      <div style ={{width:'35%', display:'flex', flexDirection:'row', marginLeft:'33%'}}>
        <input 
        type="text" class="form-control"
          onChange={this.updateArtistQuery}
          onKeyPress={this.handleKeyPress}
          placeholder='Search for an Artist'
        />
        <button className="btn btn-m" style={{backgroundColor:"#0B5345", color:"white"}} onClick={this.searchArtist}>Search</button>
      </div>
    )
  }
}

export default Search;