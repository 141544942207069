import React from 'react';
import PROJECTS from '../data/projects';

const Project = props => {
  const { title, image, description, link, tools } = props.project;

  return (
    <div style={{ display: 'inline-block', width: 350, margin: 10 }}>
      <h3>{title}</h3>
      <img src={image} alt='profile' style={{ width: 200, height: 120 }} />
      <h4>{tools}</h4>
      <p>{description}</p>
     
      <button class="btn btn-outline-dark btn-sm"> <a href={link}>View project</a></button>
    </div>
  )
}

const Projects = () => (
  <div>
    <h2>Highlighted Projects</h2>
    <div>
      {
        PROJECTS.map(PROJECT => (
          <Project key={PROJECT.id} project={PROJECT} />
        ))
      }
    </div>
  </div>
)

export default Projects;