import React from 'react';
import { connect } from 'react-redux';
import { setUsername } from '../actions/username';

const SetUsername = ({ setUsername }) => {
  return (
    <div class="input-group mb-3" style ={{width:'20%', display:'flex', flexDirection:'row', marginLeft:'40%'}}>
      <input  onChange={setUsername}  type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"></input>
      
    </div>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    setUsername: event => dispatch(setUsername(event.target.value))
  }
}

export default connect(null, mapDispatchToProps)(SetUsername);