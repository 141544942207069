import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startGame, cancelGame } from '../actions/settings';
import { fetchNewDeck } from '../actions/deck';
import fetchStates from '../reducers/fetchStates';
import Instructions from './Instructions';
import DrawCard from './DrawCard';
import Card from './Card';
import Guess from './Guess';
import GameState from './GameState';

class App extends Component {
  startGame = () => {
    this.props.startGame();
    this.props.fetchNewDeck();
  }

  render() {
    console.log('this', this);

    if (this.props.fetchState === fetchStates.error) {
      return (
        <div>
          <p>Please try reloading the app. An error occurred.</p>
          <p>{this.props.message}</p>
        </div>
      )
    }

    return (
      <div>
        <h2>♡ ♤ Evens or Odds ♢ ♧</h2>
        {
          this.props.gameStarted ? (
            <div className = "container-sm" style={{ width:"70%", display:"flex" ,flexDirection:"column", margin:'0 auto'}}>
              <div className="row">
                <div className = "col-sm-6">
                  <h3>The game is on!</h3>
                  <GameState />
                  <Guess />
                  <br />
                  <DrawCard />
                
                </div>
                <div className = "col-sm-6">
                  <Card />
                  <br/>
                  <button class="btn btn-danger" onClick={this.props.cancelGame}>Cancel Game</button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2>It's time to take a break and play a little game!</h2>
              <h3>A new game awaits</h3>
              <br />
              <button  class="btn btn-success" onClick={this.startGame}>Start Game</button>
              <hr />
              <Instructions />
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    settings: { gameStarted },
    deck: { fetchState, message }
  } = state;

  return { gameStarted, fetchState, message };
}

// const mapDispatchToProps = dispatch => {
//   return {
//     startGame: () => dispatch(startGame()),
//     cancelGame: () => dispatch(cancelGame()),
//     fetchNewDeck: () => dispatch(fetchNewDeck())
//   };
// }

const componentConnector = connect(
  mapStateToProps,
  { startGame, cancelGame, fetchNewDeck }
);

export default componentConnector(App);