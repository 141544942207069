import React from "react";
const AboutMe = ()=>{
    return(
        <div className = "container-sm" style={{ width:"90%", display:"flex" ,flexDirection:"column", margin:'0 auto'}}>
            <div className="row" >
                <div className = "col-sm-6" style={{textIndent: "50px", textAlign:'start'}}>
                    <p>I always thought my dream was to become a dentist. Beautiful smiles and how the human body works were fascinating me. I believed that as a dentist I can help people be more confident and smile more. So that is how I started taking college courses  and learning more about dentistry. To have an even better understanding  I decided that it would be a good idea to get a job as a dental assistant and observe the profession  closer. It is a remarkable  and passionate lifestyle which, thinking in perspective,  wasn’t my ideal future picture.</p>

                    <p>Having that in mind, I started to explore other career paths. Knowing that I was always an able and enthusiastic student, some of my software engineers friends encouraged me to give it a try for programming. 
                    I started digging into programming and I was absorbed by this boundless world. I was, and I still am thrilled to discover the backstage of web applications, how they work, and the joy of creating myself something that would make life easier.</p>

                    <p>I always perceived the access and time for education as a privilege and I am grateful that I have the opportunity to learn and grow.  For this reason I am committed to absorb as much knowledge as possible and use it to develop impactful products. </p>

                    <p>In my never-ending learning experience I gathered a reasonable toolkit. Here are some of them:
                    </p>
                    <h4>Front End:</h4><p> HTML, CSS, LESS, SCSS, Bootstrap, Material UI, Semantic UI React, Recharts JavaScript, React, React Hooks, Redux, Context API, Flask, Pillow, PubNub WTForms</p>
                </div>
                <div className="col-sm-6" style={{textIndent: "50px", textAlign:'start'}}>
                    <h4>Backend:</h4><p> Node.js, Express, Knex, RESTful API, Python, Jinja, Bcrypt, OAuth, SQL, AWS, R
                    <h4>Databases:</h4> SQLite3, PostgreSQL, MySQL
                    <h4>Testing:</h4> React-Testing-Library, Cypress, Jest, Enzyme
                    <h4>Deployment:</h4> Heroku, Netlify, Vercel, Github Pages, AWS
                    <h4>Working Tools:</h4>  VScode, PyCharm, RStudio, Jupiter Notebook, Anaconda, pgAdmin 4, Slack, Zoom, Git/GitHub, Trello, Asana, Postman
                    <h4> Other Experience:</h4>  Object-oriented programming, Algorithms, Data Structures, Virtual environments, Web Scraping, Google Analytics, Pair Programming, Remote Work, Product Vision Docs, Release Canvases, Team Work as a member of a development team, Accessibility with HTML and React, Communication </p>
                <div>
            </div>
        </div>
        </div>
    </div>
    )
}

export default AboutMe;