export const SET_GAME_STARTED = 'SET_GAME_STARTED';
export const SET_INSTRUCTIONS_EXPANDED = 'SET_INSTRUCTIONS_EXPANDED';
export const SET_GUESS = 'SET_GUESS';

export const DECK = {
  FETCH_SUCCESS: 'DECK_FETCH_SUCCESS',
  FETCH_ERROR: 'DECK_FETCH_ERROR'
};

export const DECK_DRAW = {
  FETCH_SUCCESS: 'DECK_DRAW_FETCH_SUCCESS',
  FETCH_ERROR: 'DECK_DRAW_FETCH_ERROR'
};